if (typeof elementInView == 'undefined') {


	// Get animatable scroll elements
	var scrollElements = Array.prototype.slice.call(document.querySelectorAll('[data-scroll]'));

	// Get load on scroll elements
	var loadHandlers = Array.prototype.slice.call(document.querySelectorAll('[data-unloaded]'));

	// Create full node list
	scrollElements.push.apply(scrollElements, loadHandlers);

	// Check if element is in view
	const elementInView = (el, dividend = 1) => {
		const elementTop = el.getBoundingClientRect().top;

		return (
			elementTop <=
			(window.innerHeight || document.documentElement.clientHeight) / dividend
		);
	};

	// Check if out of view
	const elementOutofView = (el) => {
		const elementTop = el.getBoundingClientRect().top;

		return (
			elementTop > (window.innerHeight || document.documentElement.clientHeight)
		);
	};

	// In view add .scrolled class
	// remove data-unloaded from load elements
	const displayScrollElement = (element) => {
		element.classList.add("scrolled");
		if (element.dataset.unloaded) {
			element.src = element.dataset.src;
			delete element.dataset.unloaded
		}
	};

	// Remove .scrolled class when off screen
	const hideScrollElement = (element) => {
		element.classList.remove("scrolled");
	};


	// Loop scroll elements array checking conditions
	const handleScrollAnimation = () => {
		scrollElements.forEach((el) => {
			if (elementInView(el, 1.25)) {
				displayScrollElement(el);
			} else if (elementOutofView(el)) {
				hideScrollElement(el)
			}
		})
	}

	// On scroll event
	window.addEventListener("scroll", () => {
		handleScrollAnimation();
	});
	// Also check on load
	handleScrollAnimation();

}


(function () {

	// Tolerance (px) is the size a viewport can chance
	// before updating css variables this prevents jerky 
	// resizing when browser chrome disappears on mobile
	var tolerance = 100;


	var vh = window.innerHeight * 0.01;
	document.documentElement.style.setProperty('--vh', `${vh}px`);
	var lastHeight = window.innerHeight;

	var vw = window.innerWidth * 0.01;
	document.documentElement.style.setProperty('--vw', `${vw}px`);
	var lastWidth = window.innerWidth;

	window.addEventListener('resize', () => {
		if (Math.abs(lastHeight - window.innerHeight) > tolerance) {
			vh = window.innerHeight * 0.01;
			document.documentElement.style.setProperty('--vh', `${vh}px`);
			lastHeight = window.innerHeight;
		}

		if (lastWidth != window.innerWidth) {
			vw = window.innerWidth * 0.01;
			document.documentElement.style.setProperty('--vw', `${vw}px`);
			lastWidth = window.innerWidth;
		}

	});
})();
